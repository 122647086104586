<template>

<div id="container">

  <b-alert
      :show="dismissCountDown"
      fade
      variant="danger"
      @dismiss-count-down="countDownChanged"
  >
      Error : {{errorMessage}}
  </b-alert>  


  <div id="header">
    <admin-header-section />
  </div>
  <div id="main">

      <a :href="getSquareAuth" v-if="access_token == undefined"><b-button>Connect square up</b-button></a>

      <b style="color:white;">--{{data}}-{{}}-</b>


        <b-table-simple hover small responsive class="dataTable">
          <b-thead>
            <b-tr class="datalistHeader">
              <b-th>Location Address</b-th>
              <b-th>ID</b-th>
              <b-th>Name</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr  :key="index" v-for="col,index in squarelocations" class="datalistBody" >
              <b-td>{{squarelocations[index].address}}</b-td>
              <b-td>{{squarelocations[index].id}}</b-td>
              <b-td>{{squarelocations[index].business_name}}</b-td>
              <b-td>
                <b-button v-on:click="getGetDeviceCode(squarelocations[index].id)" v-if="squarelocations[index].device_code == ''">Get Device Code</b-button>
                <h1>{{squarelocations[index].device_code}}</h1>
              </b-td>
            </b-tr>
          </b-tbody>        

        </b-table-simple>

  </div>  

</div>

</template>


<script>

import AdminHeaderSection from '../AdminHeaderSection.vue';
import { HTTP } from '@/plugins/http-common';


export default {
  name: 'Admin',
  components:{
    AdminHeaderSection,
  },
  mounted() {
      this.getData()
  },
  data () {  
      return {
            data:[],
            squareurl:'',
            squarelocations:[],
            squaredevicecodes:[],
            client_id:'',
            access_token: '',
            errorMessage:'',
            dismissCountDown:0,            
      };
  },
  methods: { 
    updateData(show) {
      this.show = show;
      this.getData();
    },
    rowClass() {
      return 'dataTableRow'
    },    

    getLocations()  {
        HTTP.get(
            'api/admin/squareup/locations',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token
                }
            }   
            )
        .then(response => {
            
            console.log(response.data)
            this.squarelocations = response.data.square;
        })
        .catch(e => {

            this.dismissCountDown = 5;
            this.errorMessage = 'Invalid square location pull' + e;

        })      
    },
    getGetDeviceCode(code)  {
        HTTP.get(
            'api/admin/squareup/devicecodes?code=' + code,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token
                }
            }   
            )
        .then(response => {
            
            console.log(response.data)
            this.squaredevicecodes = response.data;
        })
        .catch(e => {

            this.dismissCountDown = 5;
            this.errorMessage = 'Invalid square location pull' + e;

        })      
    },
    getData() {
        HTTP.get(
            'api/admin/squareup/check',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token
                }
            }   
            )
        .then(response => {
            
            console.log(response.data)
            this.data = response.data;
            this.client_id = response.data.square.client_id;
            this.squareurl=response.data.square.oauth_url;
            this.access_token=response.data.square.access_token;

            console.log(this.access_token)
            if (this.access_token) {
              if (this.access_token.length > 0)
              {
                  this.getLocations();
              }
            }
        })
        .catch(e => {

            this.dismissCountDown = 5;
            this.errorMessage = 'Invalid square check pull' + e;

        })                
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },    
  },
  computed: {
    getSquareAuth() {
        return this.squareurl + '/oauth2/authorize?client_id=' + this.client_id + '&scope=DEVICE_CREDENTIAL_MANAGEMENT,DEVICE_CREDENTIAL_MANAGEMENT,MERCHANT_PROFILE_READ,PAYMENTS_READ,PAYMENTS_WRITE';

    }
  }  
}      


</script>

<style>

  body {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background-color: #131720;
    margin: 0px;
    padding: 0px;
    font-size: 0.8rem;
    overflow: hidden;     
    border:5px solid #131720;
  }

  .admin {
    color:white;
    width:100%;
  }

  .adminScroll{
    height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;    
  }
  .adminContainer {
    height: calc(100%);
    width: 90%;
    margin:auto;
  }
  .adminContainer h4 {
    color:white;
  }
  .adminholderSquare {
    position: relative;
    width: 100%;
    border-radius: 10px;
    background-color: #2e3444;
    height: 20vh !important;
    overflow: hidden;
  }

  .adminholderSquare:after {
    content: "";
    display: block;
    padding-bottom: 100%;
    background-color: #2e3444;    
    margin:30px;
  }

  .adminholderContentholder {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .adminholderDescptionHolder {
    border: 1vw solid transparent;
    min-height: 20vh;
    font-size: 1.6vw;
    font-weight: 700;
    line-height: 1.6vw;   
  }
  .adminOptionRow {
    height: 20vh !important;

  }
  .dataTableRow {
    color:white !important;
  }
  .adminOptionRow a {
    color:white;
  }
  .datalistTable {
      width: 100%;
  }

    .datalistpadded {
        border:1vh solid transparent;
        width:100%;
    }
    .datalistpadded h4 {
      color:white;
    }
    .datalistHeader {
        color:white;
    }
    .datalistBody {
      color:white;
    }
    .datalistBody tr:hover {
      color:white !important;
      background-color: blue !important;
    }
    .datalistActionButton {
        background-color: #b0996f !important;
        color: white !important;
        border-color: #b0996f !important;
        font-size: 1rem !important;
    }     

</style>